import { PortModel } from '@projectstorm/react-diagrams'

import { CustomLinkModel } from '../../links/CustomLink/CustomLinkModel'

import { OutPortModel } from '../OutPort/OutPortModel'

class SourcePortModel extends PortModel {

	createLinkModel() {
		return new CustomLinkModel()
	}

	canLinkToPort(port) {
		const connectedPorts = Object.values(this.links).map(link => link.targetPort)
		const alreadyConnected = connectedPorts.includes(port)
		return port instanceof OutPortModel && !alreadyConnected
	}

	deserialize(event) {
		super.deserialize(event)
		// this.options.num = event.data.num
	}

	serialize() {
		return {
			...super.serialize(),
			// num: this.options.num,
		}
	}
}

export { SourcePortModel }
