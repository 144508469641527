const servicesAllData = {
  firestore: {
    node: 'podel_firestore',
    fields: [{
      fieldName: 'projectId',
      placeholder: 'Project Id'
    },
    {
      fieldName: 'clientEmail',
      placeholder: 'Client Email'
    },
    {
      fieldName: 'privateKey',
      placeholder: 'Private Key'
    }]
  },
  jwt: {
    node: 'podel_jwt',
    fields: [{
      fieldName: 'secretKey',
      placeholder: 'Secret Key'
    }]
  },
  stripe: {
    node: 'podel_stripe',
    fields: [{
      fieldName: 'secretKey',
      placeholder: 'Secret Key'
    }]
  },
  easydb: {
    node: 'podel_easydb',
    fields: [{
      fieldName: 'database',
      placeholder: 'Database'
    },
    {
      fieldName: 'token',
      placeholder: 'Token'
    }]
  },
  mongodb: {
    node: 'podel_mongodb',
    fields: [{
      fieldName: 'connectionString',
      placeholder: 'Connection String (URI)'
    }]
  }
}

const servicesData = Object.entries(servicesAllData).reduce((obj, [service, data]) => {obj[service] = data.fields; return obj}, {})
export { servicesData }

const podelToService = Object.entries(servicesAllData).reduce((obj, [service, data]) => {obj[data.node] = service; return obj}, {})
export { podelToService }

const cleanServices = (services) => {
  const newServices = {}
  Object.entries(services)
    .forEach(([name,service]) => {

      Object.entries(service.data)
        .filter(([, fieldData]) => !(fieldData?.startsWith('***')))
        .forEach(([fieldName, fieldData]) => {
          Object.assign(newServices, { ...newServices, [name]: { data: { ...newServices[name]?.data, [fieldName]: fieldData }}})
        })
  })
  return newServices
}
export { cleanServices }

const getNodes = (model) => Object.values((model?.layers ? model : model?.data)?.layers[1].models ?? {})
export { getNodes }

const serviceConfigured = (model, node) => (model?.project?.services?.[podelToService[node.options?.type ? node.options.type : node.type]])
export { serviceConfigured }

const nodesConfigured = (model) => getNodes(model).every((node) => Object.keys(podelToService).includes(node.options?.type ? node.options.type : node.type) ? serviceConfigured(model, node) : true)
export { nodesConfigured }

const getNodesNotConfigured = (model) => getNodes(model).filter((node) => Object.keys(podelToService).includes(node.options?.type ? node.options.type : node.type) && !serviceConfigured(model, node))
export { getNodesNotConfigured }

const serviceConfiguredModel = (model, selectedNode) => {
  const serviceName = podelToService[selectedNode?.options.type]
  return serviceName ? !!model?.project?.services?.[serviceName] : true
}
export { serviceConfiguredModel }