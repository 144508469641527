import React, { useState, memo } from 'react'

import { Icon, Box, Flex } from '@chakra-ui/react'

import { PortWidget } from '@projectstorm/react-diagrams'

import { useDispatch, useSelector } from 'react-redux'

import { Label } from './Components/ResponseLabel'

import { isEqualSet } from '../../../utilities/sets'

import { useClick } from '../../../utilities/touch'

const hasPortsChange = (a,b) => !isEqualSet(a.map(p => p.options.name), b.map(p => p.options.name))

const CustomPortWidget = ({port, engine, style, reverse}) => {
	const [isHovered, setIsHovered] = useState(false)

	return (
	<PortWidget style={{display: 'flex', alignItems: 'center', ...style}} port={port} engine={engine}>
		{isHovered && <span style={{position: 'absolute', backgroundColor: 'rgba(251, 123, 129, 1)', color: 'white', padding: '10px', fontSize: '22px', textAlign: 'center', borderRadius: '4px', ...(reverse ? {right: '50px'} : {left: '48px'})}}>{port.options.name}</span>}
		<span style={{width: '36px', height: '36px', borderRadius: '50%', backgroundColor: 'white', cursor: 'pointer', border: '2px solid rgba(251, 123, 129, 0.5)'}} className="hover-opacity" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}/>
	</PortWidget>
)}

// Logic to avoid triggering settings after dragging
// Would be good to find a way to simplify this
const HeaderContent = ({onSettings, image}) => {
	const eventHandlersProps = useClick(onSettings)
	return (
		<div style={{flex: 1}} {...eventHandlersProps}>
			<div style={{flex: 1, display: 'flex', justifyContent: 'center', display: 'flex'}}>
				<img src={image} style={{width: '100%', filter: 'grayscale(100%) brightness(60%)'}} draggable={false}/>
			</div>
		</div>
	)
}

const PortsContent = memo(({ports, engine, reverse}) => {
	return (
	ports.map((port, index) => 	
		<Flex key={index} alignItems="center" marginBottom={index !== ports.length -1 ? '12px' : '0px'} justifyContent="center">
			<CustomPortWidget port={port} engine={engine} reverse={reverse}/>
		</Flex>)
)})

const PodelBaseWidget = ({node, engine, name, serviceName, minimal, allowDelete, image, children}) => {

	const dispatch = useDispatch()

	const setShowDeleteNodeDialog = (show) => dispatch({type: 'SET_SHOW_DELETE_NODE_DIALOG', showDeleteNodeDialog: show})
	const setShowConfigureServicesDialog = (show) => dispatch({type: 'SET_SHOW_CONFIGURE_SERVICES_DIALOG', showConfigureServicesDialog: show})

	const setSelectedNode = () => dispatch({type: 'SET_SELECTED_NODE', selectedNode: node})
	const setSelectedEngine = () => dispatch({type: 'SET_SELECTED_ENGINE', selectedEngine: engine})

	const [showSettingsDialog, setShowSettingsDialog] = useState(false)

	const onSettings = () => {
		setSelectedNode()
		setSelectedEngine()
		setShowSettingsDialog(true)
	}

	const onConfigure = () => {
		setSelectedNode()
		setSelectedEngine()
		setShowConfigureServicesDialog(true)
	}

	const onDeleteNode = () => {
		setSelectedNode()
		setSelectedEngine()
		setShowDeleteNodeDialog(true)
	}

	useSelector(state => state.podelReducer.refresh) // triggers redraw from external factors (eg. notifications)
	
	const model = useSelector(state => state.podelReducer.model)
	const servicesConfigured = serviceName ? !!model?.project?.services?.[serviceName] : true

	const sourcePortsPrev = React.useMemo(() => node.getSourcePorts(),[node])
	const sourcePortsNew = node.getSourcePorts()
	const sourcePorts = hasPortsChange(sourcePortsPrev, sourcePortsNew) ? sourcePortsNew : sourcePortsPrev
	
	const outPortsPrev = React.useMemo(() => node.getOutPorts(),[node])
	const outPortsNew = node.getOutPorts()
	const outPorts = hasPortsChange(outPortsPrev, outPortsNew) ? outPortsNew : outPortsPrev

	const debugType = node.getDebug()?.type
	
	const containerWidth = minimal ? '180px' : '300px'

	return (
		<Box border="solid #aeaeae 2px" width={containerWidth} boxShadow="2px 2px 2px 2px #88888820" backgroundColor="white" borderRadius="xl">
			<Label debugType={debugType}/>
			<Flex>
				{<Flex flexDirection="column" margin="auto 0px auto -19px" justifyContent="center" position="absolute" top="0" bottom="0" left="0">
					<PortsContent ports={sourcePorts} engine={engine} reverse={true}/>
				</Flex>}
				<Flex flexDirection="row" alignItems="center" flex="1" height="120px" margin="0px 32px">
					<HeaderContent node={node} engine={engine} name={name} allowDelete={allowDelete} servicesConfigured={servicesConfigured} onSettings={onSettings} onConfigure={onConfigure} onDeleteNode={onDeleteNode} image={image}/>
				</Flex>
				{<Flex display="flex" flexDirection="column" margin="auto -19px auto 0px" justifyContent="center" position="absolute" top="0" bottom="0" right="0">
					<PortsContent ports={outPorts} engine={engine} reverse={false}/>
				</Flex>}
			</Flex>
			{!servicesConfigured && <div style={{backgroundColor: 'rgba(251, 123, 129, 0.75)', padding: '6px', color: 'white', display: 'flex', justifyContent: 'center', alignItems: 'center'}}><Icon style={{marginRight: '10px'}} size={16} icon="cog" color={'white'}/>Configure</div>}
			{children && React.cloneElement(children, { showSettingsDialog, setShowSettingsDialog })}
		</Box>
	)
}

export { PodelBaseWidget }