import { useState } from 'react'

let isTouchDevice
try {
	document.createEvent('TouchEvent')
	isTouchDevice = true
} catch (e) {
	isTouchDevice = false
}
export {isTouchDevice}

const useClick = (fn = () => {}) => {
	const [moved, setMoved] = useState(false)
	const [down, setDown] = useState(false)
	const onMove = () => {
		if (down) {
			setMoved(true)
		}
	}
	const onUp = () => {
		if (!moved) {
			fn()
		}
		setDown(false)
		setMoved(false)
	}
	const onDown = () => {
		setDown(true)
	}
	// how are mouse events being triggered in touch devices?
	// should this be verified with an actual device?
	const props = {
		onMouseMove: onMove,
		onMouseUp: onUp,
		onMouseDown: onDown,
		// onTouchMove: onMove,
		// onTouchEnd: onUp,
		// onTouchStart: onDown
	}
	return props
}

export { useClick }
