import { PortModel } from '@projectstorm/react-diagrams'

import { CustomLinkModel } from '../../links/CustomLink/CustomLinkModel'

import { SourcePortModel } from '../SourcePort/SourcePortModel'

class OutPortModel extends PortModel {

	createLinkModel() {
		return new CustomLinkModel()
	}

	canLinkToPort(port) {
		const connectedPorts = Object.values(port.links).map(link => link.sourcePort)
		const alreadyConnected = connectedPorts.includes(this) //false
		return port instanceof SourcePortModel && !alreadyConnected
	}

	deserialize(event) {
		super.deserialize(event)
		// this.options.num = event.data.num
	}

	serialize() {
		return {
			...super.serialize(),
			// num: this.options.num,
		}
	}
	
}

export { OutPortModel }
