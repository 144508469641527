const general = `
The \`Request\` node is used to receive any HTTP request (GET, POST, PUT, DELETE etc). It parses the data as headers, params and body and passes them on for further processing. Note: the \`Request\` node is a special node that is required for all requests and cannot be deleted.
`
export { general }

const input = `
- The \`Request\` node has no input.
`
export { input }

const output = `
- **Headers:** Returns an \`Object\` with the specified HTTP request header fields (case-insensitive match). Learn more on [req.get](http://expressjs.com/en/api.html#req.get).
- **Params:** Returns an \`Object\` containing properties mapped to the named route "parameters". For example, if you have the route \`/user/:name\`, then the "name" property is available as \`req.query.name\`. Learn more on [req.query](http://expressjs.com/en/api.html#req.query).
- **Body:** returns an \`Object\` containing key-value pairs of data submitted in the request body. Returns a \`String\` if **rawBody** is true and request type is not \`Get\`. Learn more on [req.body](http://expressjs.com/en/api.html#req.body).
`
export { output }

const uses = `
Call an external API parse and process the response.
- Request (Get) ➡️ Webhook ➡️ Script ➡️ Response (200)\n
Receive data from a request and save the to the database.
- Request (Post) ➡️ Database ➡️ Response (200)
`
export { uses }
