import { NodeModel } from '@projectstorm/react-diagrams'

import { PortModelAlignment } from '@projectstorm/react-diagrams'

import { SourcePortModel } from '../../ports/SourcePort/SourcePortModel'
import { OutPortModel } from '../../ports/OutPort/OutPortModel'

class PodelBaseModel extends NodeModel {

	constructor(options) {
		super(options)

		this.params = {}

		this.debug = {}

		if (options?.isNew ?? true) {
			super.params = options.defaultParams

			this.updateSourcePorts([], this.options.getSourcePorts(this.params))
			this.updateOutPorts([], this.options.getOutPorts(this.params))
		}

		if (options.initResults) {
			this.results = {}
		}
	}

	updateSourcePorts(sourcePorts, newSourcePorts) {
		sourcePorts.filter(port => !newSourcePorts.includes(port)).forEach(port => this.removeOldPort(port, 'source'))
		newSourcePorts.filter(port => !sourcePorts.includes(port)).forEach(port => this.addNewPort(port, 'source'))
	}

	updateOutPorts(outPorts, newOutPorts) {
		outPorts.filter(port => !newOutPorts.includes(port)).forEach(port => this.removeOldPort(port, 'out'))
		newOutPorts.filter(port => !outPorts.includes(port)).forEach(port => this.addNewPort(port, 'out'))
	}

	removeOldPort(name, type) {

		// find port
		const port = Object.values(this.getPorts())
			.filter(port => port.options.type === type)
			.find(port => port.options.name === name)
		
		// remove connected links
		Object.values(port.links).forEach(link => link.remove())

		// remove port
		super.removePort(port)
	}

	addNewPort(port, type) {
		if (type === 'out') {
			super.addPort(new OutPortModel({ name: port, alignment: PortModelAlignment.RIGHT, type: type }))
		} else {
			super.addPort(new SourcePortModel({ name: port, alignment: PortModelAlignment.LEFT, type: type }))
		}
	}

	updateParams(newParams) {

		this.updateSourcePorts(this.options.getSourcePorts(this.params), this.options.getSourcePorts(newParams))
		this.updateOutPorts(this.options.getOutPorts(this.params), this.options.getOutPorts(newParams))

		this.params = { ...this.params, ...newParams }

		this.parent?.parent.fireEvent({ node: this }, 'nodesActionsUpdated')
	}

	updateResults(paramsObject, fireEvent = false) {
		this.results = paramsObject
		if (fireEvent) {
			this.parent?.parent.fireEvent({ node: this }, 'nodesActionsUpdated')
		}
	}

	getParams() {
		return this.params
	}

	getDebug() {
		return this.debug
	}

	getLinks() {
		const ports = Object.values(this.ports)
		const links = ports.reduce((links, port) => links.concat(Object.values(port.links)), [])
		return links
	}

	setName(name) {
		this.options.name = name

		this.parent?.parent.fireEvent({ node: this }, 'nodesActionsUpdated')
	}

	setDebug(debug) {
		if (debug !== this.debug) {
			this.debug = debug
			setTimeout(() => {
				this.debug = {}
				this.parent?.parent.fireEvent({ node: this }, 'nodesUpdatedQuiet')
			}, 5 * 1000)
		}
	}

	getOutPorts() {
		const ports = Object.keys(this.getPorts()).map(key => this.getPorts()[key])
		return ports.filter(port => port.options.type === 'out')
	}

	getSourcePorts() {
		const ports = Object.keys(this.getPorts()).map(key => this.getPorts()[key])
		return ports.filter(port => port.options.type === 'source')
	}

	deserialize(event) {
		super.deserialize(event)
		this.options.name = event.data.name
		this.options.color = event.data.color
		this.params = event.data.params
		this.results = event.data.results // should be undefined??
	}

	serialize() {
		return {
			...super.serialize(),
			name: this.options.name,
			color: this.options.color,
			results: this.results,
			params: this.params,
		}
	}
}

export { PodelBaseModel }
