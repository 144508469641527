import React, { useState, useEffect } from 'react'


import { useSelector, useDispatch } from 'react-redux'

import { SettingsPanel } from '../../../components/Panvas/SettingsPanel'

import * as helpData from './PodelInputHelpData'

import {Select, Text, Switch, Flex} from '@chakra-ui/react'

import PreventPropagation from '../../../components/Shared/PreventPropagation'

const RequestSettingsDialog = (props) => {

  const dispatch = useDispatch()
  
  const selectedNode = useSelector(state => state.pialogsReducer.selectedNode)
  const selectedEngine = useSelector(state => state.pialogsReducer.selectedEngine)
  
  const [method, setMethod] = useState('')
  const [rawBody, setRawBody] = useState(false)

  useEffect(() => {
    const method2 = selectedNode?.getParams()?.method
    setMethod(method2 ? method2 : 'get')
    const rawBody2 = selectedNode?.getParams()?.rawBody
    setRawBody(rawBody2 ? rawBody2 : false)
  }, [selectedNode, props.showSettingsDialog])

  useEffect(() => {
    if (method === 'GET') {
      setRawBody(false)
    }
  }, [method])

  const onConfirm = () => {
    selectedNode.updateParams({method, rawBody})
    dispatch({type: 'SET_REFRESH'})
    selectedEngine.repaintCanvas()
    props.setShowSettingsDialog(false)
  }

  return (
    <PreventPropagation>
      <SettingsPanel title="Request" selectedNode={selectedNode} helpData={helpData} showSettingsDialog={props.showSettingsDialog} setShowSettingsDialog={props.setShowSettingsDialog} onConfirm={onConfirm}>
        <Flex marginBottom="12px" alignItems="center">
          <Text fontSize="md">Method</Text>
          <Select marginLeft="10px"  value={method} onChange={e => setMethod(e.target.value)}>
            <option value="GET">GET</option>
            <option value="POST">POST</option>
            <option value="PUT">PUT</option>
            <option value="DELETE">DELETE</option>
            {/* <option value="HEAD">HEAD</option> */}
            {/* <option value="PATCH">PATCH</option> */}
            {/* <option value="CONNECT">CONNECT</option> */}
            {/* <option value="OPTIONS">OPTIONS</option> */}
            {/* <option value="TRACE">TRACE</option> */}
          </Select>
        </Flex>
        {method !== 'GET' && <Flex alignItems="center">
          <Text fontSize="sm">Raw Body</Text>
          <Switch checked={rawBody} onChange={e => setRawBody(e.target.checked)} marginLeft="8px"/>
        </Flex>}
      </SettingsPanel>
    </PreventPropagation>
  )
}

export { RequestSettingsDialog }

