import { DiagramModel } from '@projectstorm/react-diagrams'

import { PodelInputNodeFactory } from '../../nodes/podel_input/PodelInputNodeFactory'

const inputNodeConfig = {e: {initialConfig: {}}} // not empty

const PodelInputNode = new PodelInputNodeFactory

const getEmptyModel = (engine) => {
  
  const model = new DiagramModel()
  
  const requestNode = PodelInputNode.generateModel(inputNodeConfig) // (sizeX, sizeY) = (324, 160)
  requestNode.setPosition(0, 0)

  model.addAll(requestNode)

  const offsetX = window.innerWidth/2-80
  const offsetY = 280
  model.setOffset(offsetX, offsetY)

  model.setGridSize(32)
  model.setZoomLevel(50)
  
  return model
}
export { getEmptyModel }

const getEmptyModel2 = () => {

  const model = new DiagramModel()

  const requestNode = PodelInputNode.generateModel(inputNodeConfig) // (sizeX, sizeY) = (324, 160)
  requestNode.setPosition(0, 0)

  model.addAll(requestNode)

  const offsetX = window.innerWidth/2-80
  const offsetY = 280
  model.setOffset(offsetX, offsetY)
  
  model.setGridSize(32)
  model.setZoomLevel(40)
  
  return model
}
export { getEmptyModel2 }