import { DefaultLinkModel } from '@projectstorm/react-diagrams'

class CustomLinkModel extends DefaultLinkModel {
	constructor() {
		super({ type: 'custom', width: 6 })

		this.params = {}
	}

	updateParams(newParams) {
		this.params = {...this.params, ...newParams}

		this.parent?.parent.fireEvent({node: this}, 'linksActionsUpdated')
	}

	getParams() {
		return this.params
	}

	deserialize(event) {
		super.deserialize(event)
		this.params = event.data.params
	}
	
	serialize() {
		return {
			...super.serialize(),
			params: this.params,
		}
	}
}

export { CustomLinkModel }