// import log_lfje from '../../assets/templates/firestore/login.json'
// import reg_deae from '../../assets/templates/firestore/register.json'
// import usr_ferr from '../../assets/templates/firestore/get_user.json'

import log_feko from '../assets/templates/managed/login.json'
import reg_frko from '../assets/templates/managed/register.json'
import usr_fqko from '../assets/templates/managed/get_user.json'

const templateServices = {
  "jwt": {
    "description": "Requires a random jwt key"
  },
  "managed": {
    "description": "Manage database service"
  }
}
export { templateServices }

const templates = [
  {
    "name": "Register",
    "services": ["Managed","Jwt"],
    "description": "User register template that uses our manage database.",
    "request": [{"type": "body", "name": "Email"}, {"type": "body", "name": "Password"}],
    "response": [{"type": "body", "name": "token"}],
    "id": "reg_frko",
    "model": reg_frko
  },
  {
    "name": "Login",
    "services": ["Managed","Jwt"],
    "description": "User login template that uses our manage database.",
    "id": "log_feko",
    "request": [{"type": "body", "name": "Email"}, {"type": "body", "name": "Password"}],
    "response": [{"type": "body", "name": "token"}],
    "model": log_feko
  },
  {
    "name": "Get User",
    "services": ["Managed","Jwt"],
    "description": "User get template that uses our manage database.",
    "request": [{"type": "header", "name": "Authorization"}],
    "response": [{"type": "body", "name": "email"}],
    "id": "usr_fqko",
    "model": usr_fqko
  },
  // {"name": "Login (Firestore)","id": "log_lfje","model": log_lfje},
  // {"name": "Register (Firestore)","id": "reg_deae","model": reg_deae},
  // {"name": "Get User (Firestore)","id": "usr_ferr","model": usr_ferr}
]
export { templates }
