import React from 'react'

import { PodelBaseWidget } from '../base_node/PodelBaseWidget'
import { RequestSettingsDialog } from './RequestSettingsDialog'

import { AbstractReactFactory } from '@projectstorm/react-canvas-core'

import { PodelBaseModel } from '../base_node/PodelBaseModel'

const getSourcePorts = (_1, _2) => {
	return []
}

const getOutPorts = (newParams) => {

	let outPorts = []
	outPorts.push('Headers') // num: 0
	outPorts.push('Params') // num: 1

	// maybe should check http verbs explicitly eg. POST, PUT, DELETE etc
	if (newParams.method !== 'GET') {
		outPorts.push(newParams.rawBody ? 'Raw Body' : 'Body')
	}

	return outPorts
}

class PodelInputNodeFactory extends AbstractReactFactory {
	constructor() {
		super(PodelInputNodeFactory.CONFIG.type)
		// if (typeof window !== 'undefined') {
		// }
	}

	generateReactWidget(event) {
		return (
			<PodelBaseWidget engine={this.engine} node={event.model} {...PodelInputNodeFactory.CONFIG}>
				<RequestSettingsDialog/>
			</PodelBaseWidget>
		)
	}

	generateModel(e) {
		const defaultOptions = {
			type: 'podel_input',
			defaultParams: { method: "GET", rawBody: false },
			isNew: !(e?.initialConfig),
			getSourcePorts,
			getOutPorts
		}
		return new PodelBaseModel(defaultOptions)
	}

	static get CONFIG() {
		return {
			type: 'podel_input',
			name: 'Request',
			icon: 'layout-linear',
			show: false,
			allowDelete: false,
			image: require('./logo.png')
		}
	}
}

export { PodelInputNodeFactory }
