import {Box} from '@chakra-ui/react'

const PreventPropagation = ({children})=> {
	return (
		<Box onMouseDown={(e) => e.stopPropagation()}>
				{children}
		</Box>
	)
}

export default PreventPropagation