import React from 'react'

import { Input, Button, Flex } from '@chakra-ui/react'

const ServiceRow = ({service, services, defaultServices={}, setServices, showCancel = true}) => {
  const serviceName = service.name
  const fieldName = service.fieldName
  const placeholder = service.placeholder
  
  const updateServices = (value) => {
    const newServices = {...services, [serviceName]: { data: services[serviceName] ? { ...services[serviceName].data, [fieldName]: value} : { [fieldName]: value} }}
    setServices(newServices)
  }

  const resetService = () => {
    const newServices = {...services, [serviceName]: { data: { ...services[serviceName].data, [fieldName]: ''} }}
    setServices(newServices)
  }

  const cancelResetService = () => {
    const oldValue = defaultServices[serviceName]?.data[fieldName]
    const newServices = {...services, [serviceName]: { data: { ...services[serviceName].data, [fieldName]: oldValue} }}
    setServices(newServices)
  }

  const fieldValue = services[serviceName]?.data?.[fieldName] ?? ''
  if (fieldValue?.startsWith('***')) {
    return (
      <Flex alignItems="center" marginBottom="10px">
        <Input value={placeholder} onChange={e => updateServices(e.target.value)} width='100%' isDisabled/>
        <Button marginLeft='4px' onClick={e => resetService()}>Reset</Button>
      </Flex>
    )
  } else {
    return (
      <Flex alignItems="center" marginBottom="10px">
        <Input value={fieldValue} onChange={e => updateServices(e.target.value)} placeholder={placeholder} width='100%'/>
        {showCancel && <Button marginLeft='4px' onClick={e => cancelResetService()} variant="ghost" >Cancel</Button>}
      </Flex>
    )
  }
}
export { ServiceRow }
