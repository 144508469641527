import React, { useState, useCallback } from 'react'

import ReactMarkdown from 'react-markdown'

import { ServiceRow } from '../Shared/ServicesContainer'

import { serviceConfiguredModel } from '../../utilities/services'

import { cleanServices, servicesData, podelToService } from '../../utilities/services'

import { useSelector, useDispatch } from 'react-redux'

import { useRouter } from 'next/router'

import * as PodelActions from '../../store/podel'

import { Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Button, Box, Heading, Text, Flex } from "@chakra-ui/react"

import ChakraUIRenderer from '../Shared/ChakraUIRenderer'

const Body = ({showHelp, helpData, isConfigured, children, services, setServices}) => {
	
	const selectedNode = useSelector(state => state.pialogsReducer.selectedNode)

	const selectedService = podelToService[selectedNode?.options?.type]

	const props = {selectedService, servicesData, services, setServices}
	const helpDataFull = helpData ? `📌 __General__\n ${helpData.general} \n➡️ __Input__\n ${helpData.input} \n⬅️ __Output__\n ${helpData.output} \n__🚀 Use Cases__\n ${helpData.uses}` : ''
	if (!isConfigured) {
		return (
			<>
				<Heading as="h5">Environment Variables</Heading>
				<br/>
				<Box marginBottom="2">
					{selectedService && servicesData[selectedService].map((service, index) => 
						<ServiceRow key={index} {...props} service={{...service, name: selectedService}} showCancel={false}/>
					)}
				</Box>
				<ReactMarkdown components={ChakraUIRenderer()}>You can always change environment variables in `Project settings` later.</ReactMarkdown>
			</>
		)
	} else if (showHelp) {
		return <ReactMarkdown components={ChakraUIRenderer()}>{helpDataFull}</ReactMarkdown>
	} else {
		return (
			<>{children}</>
		)
	}
}

const SettingsPanel = ({ showSettingsDialog, setShowSettingsDialog, title, helpData, onConfirm, styles, children }) => {

	const [showHelp, setShowHelp] = useState(false)
	
	const model = useSelector(state => state.podelReducer.model)

	const selectedNode = useSelector(state => state.pialogsReducer.selectedNode)
	
	const isConfigured = serviceConfiguredModel(model, selectedNode)
	
	const [services, setServices] = useState({})

	const dispatch = useDispatch()

	const updateServicesLocal = (services) => dispatch(PodelActions.updateServicesLocal(services))

	const updateProject = (services) => dispatch(PodelActions.updateProject(model.projectId, null, services))

	const router = useRouter()
	const { id: modelId } = router.query

	const [, updateState] = useState();
	const forceUpdate = useCallback(() => updateState({}), []);
	
	const onConfigure = () => {
		if (modelId) {
			updateProject(cleanServices(services)).then(() => {
				updateServicesLocal(cleanServices(services))
				forceUpdate()
			})
		}
	}

	return (
		<Drawer isOpen={showSettingsDialog} placement="right" onClose={() => setShowSettingsDialog(false)} size="lg">
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader display="flex" justifyContent="space-between" alignItems="center">
					<Text size="xs">{title} Settings</Text>
					<Button variant="ghost" marginRight="8" onClick={() => setShowHelp(!showHelp)}>{!showHelp ? 'Help' : 'Back'}</Button>
				</DrawerHeader>

				<DrawerBody display="flex" flexDirection="column">
					<Flex padding="40px" marginBottom="auto" flexDirection="column" overflow="scroll">
						<Body isConfigured={isConfigured} showHelp={showHelp} helpData={helpData} children={children} services={services} setServices={setServices}/>
					</Flex>
					<Box padding="8px 16px"  borderTop="2px solid rgba(43  38  38  0.09)" backgroundColor="#f3f3f3">
				💡 	More about input and output types in the <span className="hoverable" style={{textDecoration: 'underline'}} onClick={() => setShowHelp(true)}>Help Section</span>.
					</Box>
				</DrawerBody>

				<DrawerFooter>
					{isConfigured ? 
						<>
							<Button onClick={onConfirm}>Confirm</Button>
							<Button variant="ghost" onClick={() => setShowSettingsDialog(false)}>Cancel</Button>
						</>
					 :
					 <>
						<Button onClick={onConfigure}>Configure</Button>
						<Button variant="ghost" onClick={() => setShowSettingsDialog(false)}>Cancel</Button>
					</>
					}
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	)

}

export { SettingsPanel }