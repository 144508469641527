
// DYNAMIC IMPORT

const { getEmptyModel, getEmptyModel2 } = typeof window !== 'undefined' ? require('../../diagrams/models/Empty/empty') : {}

import { saveAs } from 'file-saver'

// DYNAMIC IMPORT
const { DiagramModel } = typeof window !== 'undefined' ? require('@projectstorm/react-diagrams') : {}

import { axiosInstance } from '../../utilities/http'

import moment from 'moment'

import { debounce, merge } from 'lodash'

import { templates } from '../../utilities/templates'

import { podelToService } from '../../utilities/services'

const formatPodelTitle = (text) => {
  if (text) {
    return text.replace(/[^a-zA-Z0-9 \/]+/g, '') // remove special characters
      .replace(/\/|\s/g, '-') // remove slashes and spaces
      .replace(/--+/g, '-') // remove multiple dashes
      .replace(/^-|-$/, '') // remove first and last dashes
      .toLowerCase()
  } else {
    return 'untitled'
  }
}

const createProject = (title) => {
  return async (dispatch, getState) => {
    const { data } = await axiosInstance.post(`/api/projects/create`, { title })
    
    // const { projects } = getState().userReducer
    // dispatch({type: 'SET_PROJECTS', projects: [...projects, data]})
    
    dispatch(loadProjects())
      .then(dispatch({type: 'SET_PROJECT', project: data}))
  }
}
export { createProject }

const updateTitle = (title) => {
  return async (dispatch, getState) => {

    const { model } = getState().podelReducer

    let callId
    if (model?.id) {
      const { data } = await axiosInstance.put(`/api/podels/${model.id}/title`, { title })
      callId = data.callId
    }
    
    dispatch({type: 'SET_MODEL', model: {...model, title, callId}})
  }
}
export { updateTitle }

const updateServicesLocal = (services) => {
  return async (dispatch, getState) => {
    const { model } = getState().podelReducer
    // const project = { services }
    model.project = { ...model.project, services }

    dispatch(loadProjects())
    // dispatch({type: 'SET_PROJECTS', model})
    // dispatch({type: 'SET_MODEL', model})

  }
}
export { updateServicesLocal }

const onCreate = (router, projectId, template) => {
  return async (dispatch, getState) => {
    const data = template?.model ?? getEmptyModel2().serialize()
    const title = template?.name
    const { data: { modelId, project } } = await axiosInstance.post('/api/podels/create', { data, projectId, title })
    router.push(`/app/models/${modelId}/`)
    
    const { model } = getState().podelReducer
    dispatch({type: 'SET_MODEL', model: {...model, project}})
  }
}
export { onCreate }

const enableRecordingFix = (engine) => {
  return async () => {
    setInterval(() => {
      engine.getActionEventBus().keys = []
    }, 2000)
  }
}
export { enableRecordingFix }

const downloadModel = (engine) => {
  return async (_, getState) => {
    const { model: modelInfo } = getState().podelReducer

    const model = engine.getModel()
    const modelSerialized = model.serialize()

    const options = {
      type: 'application/json',
      name: `${formatPodelTitle(modelInfo.title)}.json`
    }
    const modelText = JSON.stringify(modelSerialized)
    const modelBlob = new Blob([modelText], options)

    saveAs(modelBlob, options.name)
  }
}
export { downloadModel }

const uploadModel = (inputRef) => {
  return async () => {
    inputRef.current.click()
  }
}
export { uploadModel }

const toggleApiClient = () => {
  return async (dispatch, getState) => {
    const { showApiClient } = getState().podelReducer
    dispatch({type: 'SET_SHOW_API_CLIENT', showApiClient: !showApiClient})

    if (!showApiClient)
      gtag('event', 'show_api_client', {})
  }
}
export { toggleApiClient }

const setApiClient = (show) => {
  return async (dispatch, getState) => {
    dispatch({type: 'SET_SHOW_API_CLIENT', showApiClient: show})
  }
}
export { setApiClient }

const onUploadFileHandler = (event, engine) => {
  return async () => {
    const modelFile = event.target.files[0]
    const modelText = await modelFile.text()
    const model = JSON.parse(modelText)

    const diagramModel = new DiagramModel()
    
    diagramModel.deserializeModel(model, engine)

    engine.setModel(diagramModel)
  }
}
export { onUploadFileHandler }

const onNodeDrop = (event, engine) => {
  return async (dispatch, getState) => {
    const cModel = engine.getModel()
    const data = JSON.parse(event.dataTransfer.getData('storm-diagram-node'))
    const node = engine.nodeFactories.factories[data.type].generateModel()
    const point = engine.getRelativeMousePoint(event)
    node.setPosition(point)
    cModel.addNode(node)
    engine.repaintCanvas()

    // TODO: fix for saved and not saved
    const { model } = getState().podelReducer
    if ((areServicesConfigured(model, node))) {
      dispatch({type: 'SET_SELECTED_NODE', selectedNode: node})
      dispatch({type: 'SET_SELECTED_ENGINE', selectedEngine: engine})
      dispatch({type: 'SET_SHOW_CONFIGURE_SERVICES_DIALOG', showConfigureServicesDialog: true})
    }
  }
}
export { onNodeDrop }

const areServicesConfigured = (model, node) => {
  const nodeName = node.options.type
  const serviceName = podelToService[nodeName]
  const hasConfiguration = Object.keys(podelToService).includes(nodeName)
  const isConfigurationSet = !model.project?.services?.[serviceName]
  return hasConfiguration && isConfigurationSet
}

// const getProject = (projectId, engine) => {
//   return async (dispatch) => {
//     const { data: doc } = await axiosInstance.get(`/api/projects/${projectId}`)
    
//     dispatch({type: 'SET_PROJECT', project: doc})
//   }
// }
// export { getProject }

const updateModelServices = () => {
  return async (dispatch, getState) => {
    const { model, project } = getState().podelReducer
    const { data } = await axiosInstance.get(`/api/projects/${project.id}`)
    model.project = data
    dispatch({type: 'SET_MODEL', model})
    dispatch({type: 'SET_REFRESH'})

  }
}
export { updateModelServices }

// should run after engine.setModel(diagramModel)
// should be async
const updateLinkLocations = async (data) => {
  const links = Object.values(data.layers[0].models)
  const nodes = Object.values(data.layers[1].models)
  
  links.forEach(link => {
    const node = nodes.find(node => node.ports.some(port => link.sourcePort === port.id))
    
    const sourcePort = node.ports.find(port => link.sourcePort === port.id)
    if (sourcePort) {
      link.points[0].x = sourcePort.x
      link.points[0].y = sourcePort.y
    }

    const targetPort = node.ports.find(port => link.targetPort === port.id)
    if (targetPort) {
      link.points[1].x = targetPort.x
      link.points[1].y = targetPort.y
    }
  })
}

const getAnalytics = (modelId) => {
  return async (dispatch) => {
    const { data: doc } = await axiosInstance.get(`/api/analytics/${modelId}`)
    doc.id = modelId

    dispatch({type: 'SET_ANALYTICS', data: doc})
  }
}
export { getAnalytics }

const updateEngineModel = (modelId, data, engine) => {
  return async (dispatch) => {
    const diagramModel = new DiagramModel()
    diagramModel.deserializeModel(data, engine)
    engine.setModel(diagramModel)

    await updateLinkLocations(data)

    const listeners = {
      linksActionsUpdated: (e) => {
        // console.log('nodesActionsUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      nodesActionsUpdated: (e) => {
        // console.log('nodesActionsUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      nodesUpdated: (e) => {
        // console.log('nodesUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      nodesDragged: (e) => {
        // console.log('nodesDragged')
        const nodeType = e.nodeNew.options.type
        if (nodeType !== 'podel_input') {
          dispatch({type: 'SET_SHOW_NODE_TRASH', showNodeTrash: true})
        }
      },
      nodesMoved: (e) => {
        // console.log('nodesMoved')
        dispatch({type: 'SET_SHOW_NODE_TRASH', showNodeTrash: false})
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      linksUpdated: (e) => {
        // console.log('linksUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      offsetUpdated: (e) => {
        // console.log('offsetUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      gridUpdated: (e) => {
        // console.log('gridUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      entityRemoved: (e) => {
        // console.log('entityRemoved')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      zoomUpdated: (e) => {
        // console.log('zoomUpdated')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      selectionChanged: (e) => {
        // console.log('selectionChanged')
        onModelChangedDebounced(engine, modelId, dispatch)
      },
      nodesUpdatedQuiet: (e) => {
        // this.parent?.parent.fireEvent({node: this}, 'nodesActionsUpdated')
        // console.log('quiet')
        onModelChangedQuietDebounced(dispatch)
      }
    }
    engine.getModel().registerListener(listeners)
  }
}

const getModel = (modelId, engine) => {
  return async (dispatch) => {

    const { data: doc } = await axiosInstance.get(`/api/podels/${modelId}`)
    doc.id = modelId
    
    dispatch({type: 'SET_MODEL', model: doc})

    if (engine)
      dispatch(updateEngineModel(modelId, doc.data, engine))
  }
}
export { getModel }

const loadTemplateModel = (engine, templateId) => {
  return async (dispatch, getState) => {
    const { model } = getState().podelReducer

    const template = templates.find(template => template.id === templateId)
    if (!template) {
      const emptyModel = getEmptyModel(engine)
      engine.setModel(emptyModel)

      dispatch({type: 'SET_MODEL', model: {...model, data: emptyModel}})
    } else {
      const diagramModel = new DiagramModel()
      diagramModel.deserializeModel(template.model, engine)
      engine.setModel(diagramModel)

      dispatch({type: 'SET_MODEL', model: {...model, data: template.model}})
    }

    dispatch(updateModelServices())
  }
}
export { loadTemplateModel }

const loadModels = () => {
  return async (dispatch) => {
    const { data: models } = await axiosInstance.get(`/api/podels/list`)

    dispatch({type: 'SET_MODELS', models})
  }
}
export { loadModels }

const loadProjects = () => {
  return async (dispatch) => {
    const { data: projects } = await axiosInstance.get(`/api/projects/list`)

    dispatch({type: 'SET_PROJECTS', projects})
  }
}
export { loadProjects }

// const loadModelsAccess = () => {
//   return async (dispatch) => {
//     const { data: models } = await axiosInstance.get(`/api/podels/access`)

//     dispatch({type: 'SET_MODELS_ACCESS', modelsAccess: models})
//   }
// }
// export { loadModelsAccess }

const deleteModel = (modelId) => {
  return async (dispatch) => {
    await axiosInstance.delete(`/api/podels/${modelId}/delete`)

    dispatch(loadModels())
  }
}
export { deleteModel }

const onModelChanged = (engine, modelId) => {
  return async (dispatch) => {

    dispatch({type: 'SET_SAVING', saving: true})

    const data = engine.getModel().serialize()
    
    await axiosInstance.put(`/api/podels/${modelId}/update`, { data })
    
    dispatch({type: 'SET_LAST_SAVED', lastSaved: moment()})
    dispatch({type: 'SET_SAVING', saving: false})
  }
}
export { onModelChanged }

const onModelChangedDebounced = debounce((engine, modelId, dispatch) => {
  dispatch(onModelChanged(engine, modelId, dispatch))
}, 2 * 1000, {
  leading: false,
  trailing: true
})

const onModelChangedQuietDebounced = debounce((dispatch) => {
  dispatch({type: 'SET_REFRESH'})
}, 2 * 1000, {
  leading: false,
  trailing: true
})

const updateProject = (projectId, title, services) => {
  return async (dispatch, getState) => {
    await axiosInstance.put(`/api/projects/${projectId}/update`, { title, services })

    const { projects } = getState().userReducer
    const project = projects?.find(project => project.id === projectId) ?? {}
    project.title = title
    project.services = merge(project.services, services)

    dispatch(loadProjects())
  }
}
export { updateProject }

const deleteProject = (projectId) => {
  return async (dispatch, getState) => {
    await axiosInstance.delete(`/api/projects/${projectId}/delete`, {})

    dispatch(loadProjects())
      .then(dispatch({type: 'SET_PROJECT', project: null}))
  }
}
export { deleteProject }