import { useRouter } from 'next/router'

const withAuth = (WrappedComponent) => {
	return (props) => {
		if (typeof window !== 'undefined') {
			const Router = useRouter()
			const token = localStorage.getItem('token')
			if (!token) {
				Router.replace('/app/login')
				return null // If no token, redirect to login page
			}
			return <WrappedComponent {...props} /> // If token, render the component
		}
		return null // If on server, return null
	}
}
export {withAuth}